import React from 'react';
import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { useApiGet } from '../utils/httpClient';
import { getSpds } from '../endpoints/annualReports';

export default function SPDSelector({ spd, handleSpdSelect, reportId }) {
  const { data: spdList } = useApiGet(['spds'], getSpds, {
    enabled: true,
    retry: 1,
  });

  const onSpdSelect = (spdId) => {
    handleSpdSelect(spdId);
  };

  return spdList ? (
    <Autocomplete
      key={reportId}
      options={spdList}
      variant="standard"
      size="small"
      clearOnBlur={true}
      value={spdList.find((spdRecord) => spdRecord.id === spd)}
      getOptionLabel={(option) => option.name}
      onChange={(event, newValue) => onSpdSelect(newValue ? newValue.id : null)}
      renderInput={(params) => <TextField {...params} label="SPD" />}
    />
  ) : (
    <Skeleton>
      <TextField label="SPD" />
    </Skeleton>
  );
}
